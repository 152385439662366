<template>
    <div>
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name"></nav-bar-component>
        <div v-if="edit">
            <van-cell-group inset >
                <van-form>
                    <select-component
                        name="client_id"
                        lable="关联客户"
                        :farr="all_client"
                        :fvalue_name="details.client_name"
                        @clk="confirm_client"
                    ></select-component>
                    <select-component
                        name="type"
                        lable="费率类型"
                        :farr="all_type"
                        :fvalue_name="details.type_name"
                        @clk="confirm_type"
                    ></select-component>
                    <van-field
                        v-model="details.rate"
                        name="rate"
                        label="费率"
                        placeholder="费率"
                        :rules="[{ required: true, message: '请填写费率' }]"
                    />
                    <van-field
                        type="textarea"
                        v-model="details.note"
                        name="note"
                        label="备注"
                        placeholder="备注"
                    />
                </van-form>
                <div v-if="this.id">
                    <div class="sub_button">
                        <van-button round block type="primary" @click="edit_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="back_details">取 消</van-button>
                    </div>
                </div>
                <div v-else>
                    <div class="sub_button">
                        <van-button round block type="primary" @click="add_details">提 交</van-button>
                    </div>
                    <div class="sub_button">
                        <van-button round block type="default" @click="$router.go(-1)">返回</van-button>
                    </div>
                </div>
            </van-cell-group>
        </div>
        <div v-else>
            <van-cell-group inset >
                <van-cell title="关联客户" :value="details.client_name" />
                <van-cell title="费率类型">
                    <template #right-icon>
                        <van-tag plain type="success" v-if="details.type===0">仓储费</van-tag>
                        <van-tag plain type="primary" v-else-if="details.type===1">仓租费</van-tag>
                        <van-tag plain type="warning" v-else-if="details.type===2">应收装卸费</van-tag>
                        <van-tag plain type="warning" v-else-if="details.type===3">应收装卸费（托盘）</van-tag>
                        <van-tag plain type="warning" v-else-if="details.type===4">应付装卸费</van-tag>
                        <van-tag plain type="success" v-else-if="details.type===5">仓储费（托盘）</van-tag>
                    </template>
                </van-cell>
                <van-cell title="费率" :value="details | diy_rate" />
                <van-cell title="备注" :value="details.note" />
            </van-cell-group>
            <div class="sub_button">
                <van-button v-if="this.$_has('修改客户存储费率')" round block type="info" @click="edit = true">修 改</van-button>
            </div>
            <div class="sub_button">
                <van-button v-if="this.$_has('删除客户存储费率')" round block type="danger" @click="on_del">删 除</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'
import NumberKeyboardComponent from '@/components/common/NumberKeyboardComponent.vue'

import { client_stock_rate_details_request,client_stock_rate_del_request,client_stock_rate_edit_request,client_stock_rate_add_request } from '@/network/WareHouse/ClientStockRate'
import { client_list_request } from '@/network/list.js'

export default {
    name:'ClientStockRateDetailsComponent',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: '',
                    name: ''
                },
            },
            id: 0,
            details: {
                client_id: '',
                type: '',
                rate: '',
                note: ''
            },
            edit: false,
            all_type: [
                { id:0,name:'仓储费' },
                { id:1,name:'仓租费' },
                { id:2,name:'应收装卸费' },
                { id:3,name:'应收装卸费(托盘)' },
                { id:4,name:'应付装卸费' },
                { id:5,name:'仓储费(托盘)' },
            ],
            all_client: []
        }
    },
    computed:{
        my_nav_bar_name() {
            return this.edit === true ? ( this.id ? '修改客户费率' : '新增客户费率') : '客户费率详情'
        }
    },
    methods:{
        get_client_stock_rate_details() {
            this.$store.commit('true_loading')
            client_stock_rate_details_request(this.id)
                .then((s) => {
                    if (s.status === 0) {
                        this.details = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        on_del() {
            this.$dialog.confirm({
                title: '温馨提示',
                message: '是否确定删除？（一经删除，将无法再进行恢复，请谨慎操作！）',
            })
            .then(() => {
                this.$store.commit('true_loading')
                client_stock_rate_del_request(this.id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$toast.success('删除成功!')
                                this.$router.go(-1);//返回上一层
                            } else {
                                this.$toast.fail(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => this.$store.commit('false_loading'))
            })
            .catch(() => {});
        },
        confirm_type(e) {
            this.details.type = e.id
        },
        confirm_client(e) {
            this.details.client_id = e.id
        },
        back_details() {
            this.get_client_stock_rate_details()
            this.edit = false
        },
        edit_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                id: this.id,
                client_id: this.details.client_id,
                type: this.details.type,
                rate: this.details.rate,
                note: this.details.note
            }
            client_stock_rate_edit_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('修改成功!')
                        this.get_client_stock_rate_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        add_details() {
            this.$store.commit('true_loading')
            let data = {}
            data = {
                client_id: this.details.client_id,
                type: this.details.type,
                rate: this.details.rate,
                note: this.details.note
            }
            client_stock_rate_add_request(data)
                .then((s) => {
                    if (s.status === 0) {
                        this.$toast.success('添加成功!')
                        this.id = s.result.id
                        this.get_client_stock_rate_details()
                        this.edit = false
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_client() {
            this.$store.commit('true_loading')
            client_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_client = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        }
    },
    filters:{
        diy_rate(row) {
            if(row['type']===0) {
                return row['rate'] + ' 元/吨/天'
            } else if(row['type']===1) {
                return row['rate'] + ' 元/㎡/月'
            } else if(row['type']===2) {
                return row['rate'] + ' 元/吨/次'
            } else if(row['type']===3) {
                return row['rate'] + ' 元/托/次'
            } else if(row['type']===4) {
                return row['rate'] + ' 元/吨/次'
            } else if(row['type']===5) {
                return row['rate'] + ' 元/托/天'
            }
        }
    },
    props:{},
    created(){
        this.get_all_client()

        this.id = this.$route.query.id;
        if(this.id) {
            this.get_client_stock_rate_details()
        }
        else
        {
            this.edit = true
        }
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent,
        NumberKeyboardComponent
    },
    watch:{}
}
</script>

<style>
.sub_button {
    margin: 0.5rem;
}
</style>
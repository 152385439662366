import request from '../request'

//客户存储费率列表
export const client_stock_rate_index_request = p => {
    return request({
        method:'GET',
        url:'client_stock_rate/index',
        params: p
    })
}

//添加客户存储费率
export const client_stock_rate_add_request = d => {
    return request({
        method:'POST',
        url:'client_stock_rate/add',
        data: {
            client_id: d.client_id,
            type: d.type,
            rate: d.rate,
            note: d.note
        }
    })
}

//删除客户存储费率
export const client_stock_rate_del_request = id => {
    return request({
        method:'DELETE',
        url:'client_stock_rate/del',
        data: {
            id: id
        }
    })
}

//修改客户存储费率
export const client_stock_rate_edit_request = d => {
    return request({
        method:'PUT',
        url:'client_stock_rate/edit',
        data: {
            id: d.id,
            client_id: d.client_id,
            type: d.type,
            rate: d.rate,
            note: d.note
        }
    })
}

//获取客户存储费率详情
export const client_stock_rate_details_request = id => {
    return request({
        method:'GET',
        url:'client_stock_rate/details',
        params: {
            id
        }
    })
}

//客户存储费率状态修改
export const client_stock_rate_state_request = d => {
    return request({
        method:'PUT',
        url:'client_stock_rate/state',
        data: {
            id: d.id,
            state: d.state
        }
    })
}